<template>
  <b-row>
    <b-col
      cols="12"
      class="mb-2"
    >
      <div class="d-flex justify-content-end">
        <router-link
          :to="{ name: 'course-edit' }"
          class="btn btn-danger btn-sm"
          variant="danger"
        >{{ $t('Add') }}
        </router-link>
      </div>
    </b-col>
    <b-col
      cols="12"
      class="form_custom"
    >
      <b-card-code title="">
        <b-row>
          <b-col cols="6">
            <b-button
              variant="outline-danger"
              class="position-absolute position-bottom-0 btn-sm"
              :disabled="!ids.length"
              @click="deleteMulti"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('Delete') }}</span>
            </b-button>
          </b-col>
          <b-col md="3" />

          <b-col md="3">
            <label for="">
              {{ $t("Search") }}
            </label>
            <b-form-input
              v-model="filter.key"
              :placeholder="$t('search_key')"
              @change="loadItems"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <table
              class="table b-table"
              style="margin-top: 10px"
            >
              <thead>
                <tr>
                  <th
                    scope="col"
                    width="5"
                  >
                    <b-form-checkbox
                      v-model="cbxAll"
                      class="custom-control-danger"
                      name="check-button"
                      @change="val => toggleSelectAll(val)"
                    />
                  </th>
                  <th
                    scope="col"
                  >
                    {{ $t("name_q") }}
                  </th>
                  <th
                    scope="col"
                    width="200"
                    class="text-center"
                  >
                    Bài học
                  </th>
                  <th
                    scope="col"
                    width="200"
                    class="text-center"
                  >
                    Học viên
                  </th>
                  <th
                    width="50"
                    scope="col"
                    :aria-sort="getSortDirection('created_at')"
                    class="text-center"
                    @click="changeSortDirection('created_at')"
                  >
                    {{ $t('crt') }}
                  </th>
                  <th
                    width="10"
                    class="text-center"
                  >
                    {{ $t("opiton") }}
                  </th>
                </tr>
              </thead>
              <template v-if="isLoading">
                <tr
                  v-for="(item) in rows"
                >
                  <td colspan="1">
                    <b-skeleton animation="fade" />
                  </td>
                  <td colspan="4">
                    <b-skeleton animation="fade" />
                  </td>
                  <td colspan="2">
                    <b-skeleton animation="fade" />
                  </td>
                </tr>
              </template>
              <tr
                v-for="(item, index) in rows"
                v-else
                :id="item.id"
                :key="item.id"
              >
                <td>
                  <b-form-checkbox
                    v-model="ids"
                    :value="item.id"
                    class="custom-control-danger"
                    name="check-button"
                  />
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <span
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-tooltip.hover.top="item.status? 'Đang hoạt động' : 'Ngưng hoạt động'"
                      variant="outline-primary"
                      class="mr-1"
                      style="font-size: 8px"
                    >
                      <i
                        :class="item.status ? 'text-success' : 'text-danger '"
                        class="fas fa-circle xs"
                      />
                    </span>
                    <router-link :to="{ name: 'course-edit', params: { id: item.id, name: item.name }}">
                      <span>{{ item.name }}</span>
                    </router-link>
                  </div>
                </td>
                <td class="text-center">
                  {{ item.topics.length }} bài
                </td>
                <td
                  class="text-center"
                  @click="filterUser(item.users)"
                >
                  {{ item.users.length }}
                </td>
                <td class="text-center">
                  {{ convertDate(item.created_at) }}
                </td>
                <td class="text-center">
                  <b-dropdown
                    id="dropdown-1"
                    class="custom-position-dropdown"
                    variant="flat-sencondary"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle"
                      />
                    </template>
                    <div
                      v-b-modal.modal-edit
                      class="dropdown-item"
                    >
                      <router-link
                        class="text-black"
                        :to="{ name: 'course-edit', params: { id: item.id, name: item.name }}"
                      >
                        {{ $t('edit') }}
                      </router-link>
                    </div>
                    <div
                      class="dropdown-item"
                      @click="deleteMulti(item.id)"
                    >{{ $t('Delete') }}</div>
                  </b-dropdown>
                </td>
              </tr>
            </table>
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="pageLength"
              first-number
              last-number
              class="pagination-danger mt-2"
              align="right"
              @input="onPageChange"
            >
              <template #prev-text>
                <feather-icon
                  size="18"
                  icon="ChevronLeftIcon"
                />
              </template>
              <template #next-text>
                <feather-icon
                  size="18"
                  icon="ChevronRightIcon"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>

      </b-card-code>
    </b-col>
    <b-modal
      id="modal-edit"
      v-model="showUser"
      centered
      size="lg"
      hide-footer
      title="Danh sách học viên"
    >
      <b-table-lite
        :sticky-header="true"
        hover
        :items="dataUserCourse"
        striped
        responsive
      />

    </b-modal>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'

import {
  BRow,
  BCol,
  BButton,
  VBModal,
  BTableLite,
  BFormInput,
  BOverlay,
  BPagination,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BListGroup,
  BForm,
  BImg,
  BFormFile,
  BModal,
  BFormRadio,
  BInputGroupAppend,
  BBadge,
  BListGroupItem,
  BFormSelect,
  BFormTextarea,
  BEmbed,
  BCardText,
  VBTooltip,
  BSkeleton,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import { required } from '@validations'
import config from '@/config'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BSkeleton,
    BCardCode,
    BTableLite,
    BImg,
    vSelect,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    quillEditor,
    BCol,
    BCardText,
    draggable,
    BFormSelect,
    BEmbed,
    BFormFile,
    BFormGroup,
    BForm,
    BButton,
    BFormInput,
    BOverlay,
    BPagination,
    BFormCheckbox,
    BFormRadio,
    BInputGroupAppend,
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    BModal,
    BBadge,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      cbxAll: false,
      showUser: false,
      modalShow: false,
      currentPage: 1,
      showAddAnswer: false,
      frm: {
        name: null,
        tag: [],
        is_correct: null,
        status: false,
        type: null,
        multiple_choice_type: null,
        point: 0,
        answers: [],
        image: null,
        audio: null,
      },
      pageLength: 10,
      isShow: true,
      editModal: false,
      dragging: false,
      titleAdd: this.$t('Add'),
      file: null,
      perPageUserActive: 10,
      currentPageActive: 1,
      // eslint-disable-next-line global-require
      imgUrl: null,
      required,
      isLoading: false,
      total: 0,
      rows: [],
      ids: [],
      modalName: '',
      snowOption: {
        theme: 'snow',
        placeholder: this.$t('enter_q'),
      },
      endPoint: process.env.VUE_APP_API_ENDPOINT,
      filter: {
        key: '',
      },
      sort: {
        by: 'id',
        direction: 'desc',
      },
      answerIndex: config.course.answerIndex,
      questionTypeData: [
        {
          label: this.$t('multiple_c'),
          value: 1,
        },
        {
          label: this.$t('fill'),
          value: 3,
        },
      ],
      audioUrl: null,
      tagOptions: [],
      dataUserCourse: [],

    }
  },
  watch: {
    ids: {
      handler(value) {
        this.cbxAll = value.length && this._.difference(this.rows.map(v => v.id), value).length === 0
      },
    },
  },
  created() {
    this.loadItems()
  },
  methods: {
    filterUser(items) {
      this.showUser = true
      const users = []
      if (items.length) {
        items.map((val, k) => {
          users.push({
            stt: k + 1,
            name: val.name,
            phone: val.phone,
            Dk: this.convertDate(val.created_at),
            DNGN: this.convertDate(val.last_login),
          })
        })
      }
      // console.log(users)
      this.dataUserCourse = users
    },
    deleteMulti(id) {
      this.$swal({
        title: this.$t('message.Confirm_delete'),
        text: false,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          if (typeof id === 'number') this.ids = [id]
          this.$http.delete('/courses', {
            data: {
              ids: this.ids,
            },
          })
          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.$bvToast.toast(this.$t('message.delete_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
              this.loadItems()
              // eslint-disable-next-line no-unused-vars
            }).catch(e => {
              console.log('e', e)
              this.$bvToast.toast(this.$t('message.delete_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
              this.ids = []
            })
        }
      })
    },
    toggleSelectAll(value) {
      if (value) {
        this.ids = this.rows.map(v => v.id)
      } else {
        this.ids = []
      }
    },
    onPageChange(currentPage) {
      this.currentPage = currentPage
      this.loadItems()
    },
    loadItems() {
      this.isLoading = true
      this.$http.get('/courses', {
        params: {
          perPage: this.pageLength,
          page: this.currentPage,
          key: this.filter.key,
          order_by: this.sort.by,
          order_direction: this.sort.direction === 'ascending' ? 'asc' : 'desc',
        },
      })
        .then(res => {
          this.total = res.data.total
          this.pageLength = res.data.per_page
          this.rows = res.data.data.map(v => {
            // eslint-disable-next-line no-param-reassign
            v.status = v.status === 1
            return v
          })
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    getSortDirection(sortBy) {
      if (sortBy === this.sort.by) {
        return this.sort.direction
      }

      return 'none'
    },
    changeSortDirection(sortBy) {
      if (this.sort.by !== sortBy) {
        this.sort.direction = 'none'
        this.sort.by = sortBy
      }
      if (this.sort.direction === 'none') {
        this.sort.direction = 'ascending'
      } else if (this.sort.direction === 'ascending') {
        this.sort.direction = 'descending'
      } else {
        this.sort.by = 'order'
        this.sort.direction = 'desc'
      }
      this.loadItems()
    },
    convertDate(iosDate) {
      if (!iosDate) return ''
      const date = new Date(iosDate)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let dt = date.getDate()

      if (dt < 10) {
        dt = `0${dt}`
      }
      if (month < 10) {
        month = `0${month}`
      }

      return `${dt}/${month}/${year}`
    },
  },
}
</script>
